.not-found-container {
    text-align: center;
    margin-top: 50px;
    padding: 20px;
  }
  
  .not-found-container h1 {
    font-size: 6rem;
    color: #ff6f61;
    margin-bottom: 20px;
  }
  
  .not-found-container p {
    font-size: 1.5rem;
    color: #6c757d;
    margin-bottom: 30px;
  }
  
  .btn-theme {
    display: inline-block;
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
  }
  
  .btn-theme:hover {
    background-color: #0056b3;
    color: #fff;
    transform: translateY(-2px);
  }
  