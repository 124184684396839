/* ChatIcon.css */
.chat-icon-container {
    position: fixed;
    bottom: 35px;
    right: 35px;
    z-index: 1000;
  }
  
  .chat-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 65px;
    background: #4162FF;
    border-radius: 50%;
    box-shadow: 0 4px 20px rgba(65, 98, 255, 0.3);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
    position: relative;
    text-decoration: none;
  }
  
  .chat-icon:hover {
    transform: translateY(-5px) scale(1.05);
    background: #5472FF;
    box-shadow: 0 8px 25px rgba(65, 98, 255, 0.4);
  }
  
  .chat-icon:active {
    transform: translateY(0) scale(0.95);
  }
  
  .chat-icon-svg {
    color: white;
    transition: transform 0.3s ease;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
  }
  
  .chat-icon:hover .chat-icon-svg {
    transform: scale(1.1);
  }
  
  /* Pulsing effect */
  .chat-icon-pulse {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #4162FF;
    border-radius: 50%;
    opacity: 0;
    animation: pulse 2s infinite cubic-bezier(0.34, 1.56, 0.64, 1);
    pointer-events: none;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 0.6;
    }
    100% {
      transform: scale(1.8);
      opacity: 0;
    }
  }
  
  /* Tooltip */
  .chat-tooltip {
    position: absolute;
    right: calc(100% + 18px);
    top: 50%;
    transform: translateY(-50%);
    background: #2D3748;
    color: white;
    padding: 10px 16px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 500;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .chat-tooltip::after {
    content: '';
    position: absolute;
    right: -8px;
    top: 50%;
    transform: translateY(-50%);
    border-width: 8px 0 8px 8px;
    border-style: solid;
    border-color: transparent transparent transparent #2D3748;
  }
  
  .chat-icon:hover .chat-tooltip {
    opacity: 1;
    visibility: visible;
    transform: translateY(-50%);
  }
  
  /* Optional shine effect */
  .chat-icon::before {
    content: '';
    position: absolute;
    top: 0;
    left: -75%;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%
    );
    transform: skewX(-25deg);
    transition: 0.5s;
  }
  
  .chat-icon:hover::before {
    animation: shine 0.7s;
  }
  
  @keyframes shine {
    100% {
      left: 125%;
    }
  }
  
  /* Mobile responsiveness */
  @media screen and (max-width: 768px) {
    .chat-icon-container {
      bottom: 20px; /* reduced from 35px */
      right: 20px;  /* reduced from 35px */
    }
    .chat-icon {
      width: 45px;  /* reduced from 65px */
      height: 45px; /* reduced from 65px */
    }
    .chat-icon-svg {
      /* Adjust size if needed */
      width: 24px;
      height: 24px;
    }
    .chat-tooltip {
      font-size: 13px;
      padding: 8px 12px;
    }
  }
  