.footer-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-link {
    color: #b3b3b3;
    text-decoration: none;
    font-size: 14px;
    display: block;
    margin: 5px 0;
    transition: color 0.3s ease;
  }
  
  .footer-link:hover {
    color: white;
  }
  
  /* Centering the social icons under "Follow us" */
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 10px; /* Spacing between icons */
  }
  
  /* Social media icons styling */
  .MuiIconButton-root {
    color: #b3b3b3;
    transition: color 0.3s ease;
  }
  
  .MuiIconButton-root:hover {
    color: white;
  }
  