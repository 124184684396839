.logo {
  width: 150px;
  height: auto;
  margin-right: 8px;
  transition: transform 0.2s ease-in-out;
}

.logo:hover {
  transform: scale(1.1);
}

button:hover, a:hover {
  color: #ccc;
}

.MuiAppBar-root {
  background-color: #fff;
  color: black;
}

.MuiToolbar-root {
  display: flex;
  justify-content: space-between;
}

input::placeholder {
  font-style: italic;
}
.MuiAvatar-root {
  border: 2px solid white; /* Optional: add a border for better visibility */
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.MuiAvatar-root:hover {
  transform: scale(1.1);
}
.search-results {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
}

.result-thumbnail {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  margin-right: 10px;
}

.result-title {
  font-weight: bold;
  margin: 0;
}

.result-description {
  font-size: 0.9rem;
  color: gray;
  margin: 0;
}

